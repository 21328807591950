const axios = require('axios')
const { baseURL } = require('@/assets/js/config')
const $http = axios.create({
    baseURL
})

const state = {
	current_user:localStorage.getItem('current_user') || {},
	users:[],
    user:{},
	userToken : localStorage.getItem('userToken') || null,
	videos:[],
	categorys:[],
	sum: 0,
	catCount: 0,
	vidCount: 0
}

const getters = {
	fetchToken: (state) => state.userToken,
	fetchSum: (state) => state.sum,
	fetchVidCount: (state) => state.vidCount,
	fetchCatCount: (state) => state.catCount,
	isAuthenticated: (state) => !!(state.userToken),
    fetchUsers : (state) => state.users,
	fetchUser: (state) => state.user,	
	fetchAssignedUserVideos: (state) => state.videos,
	fetchAssignedUserCategorys: (state) => state.categorys
}

const actions = {
	loginUser({ commit }, {username, password}) {
		return $http.post(`loginAdmin`,{username, password}).then(res => {
			commit('LOGIN_USER', {user: res.data.user, token: res.data.token});
		})
	},
	logoutUser({ commit }) {
		commit('LOGOUT_USER');
	},
	loadSum({commit}, {token}){
		return $http.get(`user/sumFees`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_SUM', response.data.data[0].totalFees)
		})
	},
	loadVidCount({commit}, {token}){
		return $http.get(`user/vidCount`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_VIDCOUNT', response.data.data)
		})
	},
	loadCatCount({commit}, {token}){
		return $http.get(`user/catCount`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_CATCOUNT', response.data.data)
		})
	},
	loadUsers({commit}, { filter , token}){
		const query_string = new URLSearchParams(filter).toString();
		return $http.get(`user?${query_string}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('SET_USERS', response.data.data)
		})
	},
	loadUser({ commit }, { id, token }) {
		return $http.get(`user/${id}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_USER', response.data.data)
		})
	},
	createNewUser({commit}, {user, token}){
		return $http.post('user', user, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('ADD_USER', response.data.data)
		})
	},
	updateUser({ commit }, { id, user, token}) {
		return $http.put(`user/${id}`, user, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('UPDATE_USER', response.data.data)
		})
	},
	deleteUser({ commit }, {id, token}){
		return $http.delete(`user/${id}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(() => {
			commit('DELETE_USER', id)	
		})
	},
	getAssignedUserVideos({ commit }, {id, token}){
		return $http.get(`user/${id}/assignUser`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('ASSIGN_VIDEOS', response.data.data)
		})	
	},
	assignUserVideos({ commit }, {id, videos, token}){
		return $http.put(`user/${id}/assignUser`, videos, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('UPDATE_VIDEOS', response.data.data)
		})	
	},
	getAssignedUserCategorys({ commit }, {id, token}){
		return $http.get(`user/${id}/assignCategory`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('ASSIGN_CATEGORYS', response.data.data)
		})	
	},
	assignUserCategorys({ commit }, {id, categorys, token}){
		return $http.put(`user/${id}/assignCategory`, categorys, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('UPDATE_CATEGORYS', response.data.data)
		})	
	}
}

const mutations = {
	LOGIN_USER(state, {user, token}) {
		localStorage.setItem('userToken', token)
		localStorage.setItem('current_user', user)
		state.userToken = token;
		state.current_user = user;
	},
	LOGOUT_USER(state) {
		localStorage.removeItem('userToken')
		localStorage.removeItem('current_user')
		state.userToken = null;
		state.current_user = {};
	},
    SET_USERS : (state, users) => {
		state.users = users
	},
	SET_USER : (state, user) => {
		state.user = user
	},
	UPDATE_USER: (state, user) => {
		state.user = user
		state.users = state.users.map(u => (u.id === user.id) ? user : u)
	},
	ADD_USER : (state, user) => {
		state.users.push(user)
	},
	DELETE_USER : (state, id) => {
		state.users = state.users.filter(u => u.id !== id)
	},
	ASSIGN_VIDEOS : (state, videos) => {
		state.videos = videos
	},
	UPDATE_VIDEOS : (state, videos) => {
	},
	ASSIGN_CATEGORYS : (state, categorys) => {
		state.categorys = categorys
	},
	UPDATE_CATEGORYS : (state, categorys) => {
	},
    SET_SUM : (state, sum) => {
		state.sum = sum
	},
    SET_VIDCOUNT : (state, vidCount) => {
		state.vidCount = vidCount
	},
    SET_CATCOUNT : (state, catCount) => {
		state.catCount = catCount
	},
}

export default {
	state, getters, actions, mutations
}