<template>
  <div>
    <video class="rounded" :id="playerId" controls></video>
  </div>
</template>
    
    <script>
import Hls from "hls.js";
import { baseVideoUrl } from "@/assets/js/config";

export default {
  name: "ShowVideo",
  props: {
    videourl: {
      type: String,
    },
  },
  data() {
    return {
      playerId: `video-player-${Math.random().toString(36)}`, // Generate a unique player ID
    };
  },
  watch: {
    videourl: "initializeVideoPlayer",
  },
  methods: {
    initializeVideoPlayer() {
      const url = baseVideoUrl + this.videourl;
      if (url && url.trim() !== "") {
        const dom_video_element = document.getElementById(this.playerId);
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(url);
          hls.attachMedia(dom_video_element);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {});
        }
      }
    },
  },
  mounted() {
    // Call the initialization method when the component is mounted
    this.initializeVideoPlayer();
  },
};
</script>
    
    <style>
/* Add your CSS styles here if needed */
</style>
    