<template>
  <div class="px-4 sm:px-2 lg:px-0">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-6 text-gray-900">Users</h1>
      </div>
      <div class="backdrop" v-if="deleteModalVisible" @click="deleteCloseModal">
        <delete-user
          @close="deleteCloseModal"
          v-if="deleteModalVisible"
          :id="id"
        />
      </div>
      <div class="backdrop" v-if="modalVisible" @click="closeModal">
        <set-user
          @close="closeModal"
          v-if="modalVisible"
          :editMode="isEditMode"
          :id="id"
        />
      </div>
      <div class="backdrop" v-if="assignModalVisible" @click="closeAssignModal">
        <assign-user-video
          @end="closeAssignModal"
          v-if="assignModalVisible"
          :assignMode="isAssignMode"
          :id="id"
        />
      </div>
      <div
        class="backdrop"
        v-if="assignCategoryModalVisible"
        @click="closeCategoryAssignModal"
      >
        <assign-user-category
          @end="closeCategoryAssignModal"
          v-if="assignCategoryModalVisible"
          :assignMode="isCategoryAssignMode"
          :id="id"
        />
      </div>
      <div
        class="backdrop"
        v-if="categoryModalVisible"
        @click="closeCategoryModal"
      >
        <show-categories
          @close="closeCategoryModal"
          v-if="categoryModalVisible"
          :id="id"
        />
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          @click="openModalForCreate"
          class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add user
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 -mx-4">
      <div class="bg-white rounded-lg p-4">
        <label
          for="firstname"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Firstname</label
        >
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              v-model="filter.firstname"
              type="text"
              name="firstname"
              id="firstname"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Firstname"
              @keydown.enter="filterBy"
            />
          </div>
          <button
            type="button"
            @click="filterBy"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Search
          </button>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4">
        <label
          for="phoneno"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Mobile</label
        >
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              v-model="filter.phoneno"
              type="text"
              name="phoneno"
              id="phoneno"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Mobile"
              @keydown.enter="filterBy"
            />
          </div>
          <button
            type="button"
            @click="filterBy"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Search
          </button>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4">
        <label
          for="email"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Email</label
        >
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              v-model="filter.email"
              type="email"
              name="email"
              id="email"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Email"
              @keydown.enter="filterBy"
            />
          </div>
          <button
            type="button"
            @click="filterBy"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Search
          </button>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4">
        <label
          for="state"
          class="block text-sm font-medium leading-6 text-gray-900"
          >State</label
        >
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              v-model="filter.state"
              type="text"
              name="state"
              id="state"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="State"
              @keydown.enter="filterBy"
            />
          </div>
          <button
            type="button"
            @click="filterBy"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="flow-root mt-5">
      <div class="-my-10 overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="max-h-custom overflow-x-auto">
            <!-- Set a fixed height and enable horizontal scrolling -->
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    SNo
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Firstname
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Lastname
                  </th>

                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Mobile
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Joined
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Rejoin Date
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Fees
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Video Count
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Category Count
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <Loading v-if="loading" />
              <tbody v-else class="divide-y divide-gray-200">
                <tr
                  v-for="(user, index) in fetchUsers"
                  :class="{
                    'bg-red-200': user.stat == 0,
                    'bg-white': user.stat == 1,
                  }"
                  :key="user.email"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                  >
                    {{ user.firstname }}
                  </td>
                  <td class="whitespace-nowrap py-4 text-sm text-gray-500">
                    {{ user.lastname }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ user.email }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ user.phoneno }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ showDateInWords(user.created) }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ user.rejoindate ? showDate(user.rejoindate) : null }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ user.totalamt }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ fetchVidCount[user.id] ? fetchVidCount[user.id] : 0 }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ fetchCatCount[user.id] ? fetchCatCount[user.id] : 0 }}
                  </td>
                  <td
                    class="flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 gap-2"
                  >
                    <button
                      @click="openModalForCategory(user.id)"
                      class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 rounded"
                    >
                      <EyeIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      @click="openModalForAssign(user.id)"
                      class="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-2 rounded"
                    >
                      <VideoCameraIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      @click="openModalForCategoryAssign(user.id)"
                      class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-2 rounded"
                    >
                      <ViewColumnsIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      @click="openModalForEdit(user.id)"
                      class="bg-indigo-600 hover:bg-indigo-900 text-white font-semibold py-2 px-2 rounded"
                    >
                      <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      @click="deleteTheUser(user.id)"
                      class="bg-red-600 hover:bg-red-900 text-white font-semibold py-2 px-2 rounded"
                    >
                      <TrashIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetUser from "@/components/User/SetUser";
import AssignUserVideo from "@/components/User/AssignUserVideo";
import AssignUserCategory from "@/components/User/AssignUserCategory";
import ShowCategories from "@/components/User/ShowCategories";
import DeleteUser from "@/components/User/DeleteUser";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Users",
  components: {
    SetUser,
    AssignUserVideo,
    AssignUserCategory,
    Loading,
    ShowCategories,
    DeleteUser,
  },
  data() {
    return {
      filter: {
        firstname: "",
        phoneno: "",
        email: "",
        state: "",
      },
      loading: true,
      deleteModalVisible: false,
      categoryModalVisible: false,
      assignModalVisible: false,
      isAssignMode: false,
      assignCategoryModalVisible: false,
      isCategoryAssignMode: false,
      modalVisible: false,
      isEditMode: false,
      id: null,
    };
  },
  computed: {
    ...mapGetters([
      "fetchUsers",
      "fetchToken",
      "fetchVidCount",
      "fetchCatCount",
    ]),
  },
  methods: {
    ...mapActions(["loadUsers", "deleteUser", "loadVidCount", "loadCatCount"]),
    openModalForCategory(userId) {
      this.id = userId;
      this.categoryModalVisible = true;
    },
    openModalForCreate() {
      this.isEditMode = false;
      this.id = null;
      this.modalVisible = true;
    },
    openModalForEdit(userId) {
      this.isEditMode = true; // Set to Edit mode
      this.id = userId;
      this.modalVisible = true;
    },
    openModalForAssign(userId) {
      this.isAssignMode = true; // Set to Edit mode
      this.id = userId;
      this.assignModalVisible = true;
    },
    closeAssignModal() {
      this.assignModalVisible = false;
    },
    openModalForCategoryAssign(userId) {
      this.isCategoryAssignMode = true; // Set to Edit mode
      this.id = userId;
      this.assignCategoryModalVisible = true;
    },
    deleteTheUser(userId) {
      this.id = userId;
      this.deleteModalVisible = true;
    },
    closeCategoryAssignModal() {
      this.assignCategoryModalVisible = false;
    },
    closeModal() {
      this.modalVisible = false;
    },
    deleteCloseModal() {
      this.deleteModalVisible = false;
    },
    closeCategoryModal() {
      this.categoryModalVisible = false;
    },
    showDateInWords(input) {
      const date = new Date(input);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const formattedDateString = `${day} ${month} ${year} at ${hours}:${minutes}:${seconds}`;
      return formattedDateString;
    },
    showDate(datetime) {
      return moment(datetime).format("DD/MM/YYYY");
    },
    filterBy() {
      this.loadUsers({ filter: this.filter, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("User loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    },
  },
  created() {
    this.loadCatCount({ token: this.fetchToken });
    this.loadVidCount({ token: this.fetchToken });
    this.loadUsers({ filter: this.filter, token: this.fetchToken })
      .then(() => {
        this.loading = false;
        console.log("User loaded successfully");
      })
      .catch((err) => {
        this.loading = false;
        console.log("Error: ", err);
      });
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure the backdrop is on top of everything */
}

.max-h-custom {
  max-height: 25rem; /* Adjust the value as needed */
}
</style>