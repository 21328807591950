<template>
  <div
    class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-white opacity-75"
  >
    <!-- Loading indicator container -->
    <div class="flex flex-col items-center">
      <!-- Loading spinner with rotation animation -->
      <div
        class="loader ease-linear rounded-full border-t-8 border-gray-200 h-24 w-24 animate-spin"
      ></div>

      <!-- Loading text -->
      <div class="mt-2 text-gray-800">Loading...</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Loading",
};
</script>
  
  