<template>
  <div>
    <Login v-if="!isAuthenticated" />
    <SideBar v-if="isAuthenticated" />
  </div>
</template>

<style>
</style>

<script>
import SideBar from "@/components/SideBar";
import Login from "@/views/Login";
import { mapGetters } from "vuex";
export default {
  components: {
    SideBar,
    Login,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
