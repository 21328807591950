<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
    >
      <!-- Your form content goes here -->
      <form @submit.prevent="proceed" @click.stop>
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h1 class="text-base font-semibold leading-7 text-gray-900">
              {{ id ? "Update" : "Add" }} Video
            </h1>
            <Loading v-if="loading" />
            <div
              v-else
              class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
            >
              <div class="col-span-full">
                <label
                  for="title"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Title</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    autocomplete="title"
                    v-model="video.title"
                    class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="type"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Type</label
                >
                <div class="mt-2">
                  <select
                    id="type"
                    name="type"
                    autocomplete="type-name"
                    v-model="video.type"
                    class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 px-3"
                  >
                    <option value="P">Paid</option>
                    <option value="F">Free</option>
                  </select>
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="ord"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Order</label
                >
                <div class="mt-2">
                  <input
                    id="ord"
                    name="ord"
                    type="text"
                    autocomplete="ord"
                    v-model="video.ord"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label
                  for="localpath"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Video {{ id ? "" : urlKey }}</label
                >
                <div v-if="!id" class="mt-2">
                  <input
                    id="localpath"
                    name="localpath"
                    type="text"
                    autocomplete="localpath"
                    v-model="video[urlKey]"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3"
                  />
                </div>
                <div v-else>
                  <ShowVideo
                    :videourl="video[urlKey]"
                    style="width: 100%; height: auto"
                  ></ShowVideo>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
    
<script>
import { mapActions, mapGetters } from "vuex";
import ShowVideo from "../ShowVideo.vue";
import Loading from "@/components/Loading.vue";
import { softwareName } from "@/assets/js/config";
export default {
  name: "SetVideo",
  components: {
    ShowVideo,
    Loading,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      urlKeyMapping: {
        "Laptop Training Institute": "localpath",
        Oklaptop: "url",
        Laptopok: "url",
      },
      loading: true,
      video: {
        id: null,
        title: null,
        url: null,
        localpath: null,
        type: "P",
        ord: null,
      },
    };
  },
  computed: {
    ...mapGetters(["fetchVideo", "fetchOrd", "fetchToken"]),
    urlKey() {
      return this.urlKeyMapping[softwareName];
    },
  },
  methods: {
    ...mapActions(["createNewVideo", "updateVideo", "loadVideo", "loadOrd"]),
    proceed() {
      if (this.id) {
        this.updateVideo({
          id: this.id,
          video: this.video,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "Video updated successfully",
              type: "success",
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.msg,
              type: "error",
            });
            this.$emit("close");
          });
      } else {
        this.createNewVideo({
          video: this.video,
          token: this.fetchToken,
        })
          .then(() => {
            this.$toast.open({
              message: "Video created successfully",
              type: "success",
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.msg,
              type: "error",
            });
            this.$emit("close");
          });
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    fetchVideo(new_val) {
      for (let key in this.video) {
        let value = new_val[key];
        this.video[key] = value;
      }
    },
    fetchOrd(new_val) {
      if (!this.id) {
        this.video.ord = Math.floor(new_val) + 1;
      }
    },
  },
  async created() {
    if (!this.id) {
      this.loadOrd({ token: this.fetchToken })
        .then(() => {
          this.video.ord = Math.floor(this.fetchOrd) + 1;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    } else {
      this.loadVideo({ id: this.id, token: this.fetchToken })
        .then(() => {
          this.loading = false;
          console.log("Video loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    }
  },
};
</script>