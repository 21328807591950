import { createStore} from 'vuex';

import user from './modules/user'
import video from './modules/video'
import category from './modules/category';

export default new createStore({
  modules : {
    category, user, video,
  }
})
