import { createRouter, createWebHistory } from 'vue-router'
import Users from '../views/Users'
import Videos from '../views/Videos'
import Categories from '../views/Categories'
import Login from '../views/Login'

const routes = [
  
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
    meta: { requiresAuth: true }
  },
  {
    path: '/categories',
    name: 'categories',
    component: Categories,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
