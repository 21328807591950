<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 mx-4 sm:mx-auto max-w-3xl w-full max-h-screen overflow-y-auto"
      style="width: 60%; max-width: 1200px"
    >
      <div @click.stop>
        <h1 class="text-base font-semibold leading-7 text-gray-900 pl-1 pb-6">
          Categories
        </h1>
        <Loading v-if="loading" />
        <ul
          v-else
          role="list"
          class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
        >
          <li
            v-for="category in categorys"
            :key="category.id"
            class="overflow-hidden rounded-xl border border-gray-200"
          >
            <div
              class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6"
            >
              <div class="text-sm font-semibold leading-6 text-gray-900">
                {{ category.catname }}
              </div>
            </div>
            <dl
              class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6"
            >
              <div
                v-for="video in categoryVideos[category.id]"
                :key="video.id"
                class="flex justify-between gap-x-4 py-3"
              >
                <div class="text-gray-700">{{ video.title }}</div>
                <!-- <dt class="text-gray-700">{{ video.title }}</dt> -->
                <!-- <dd class="text-gray-500"></dd> -->
              </div>
            </dl>
          </li>
        </ul>
        <ol class="text-sm leading-6 text-gray-900 mt-4">
          <h2 class="text-sm font-semibold leading-6 p-2 text-gray-900">
            Videos
          </h2>
          <li
            v-for="userVideo in userVideos"
            :key="userVideo.id"
            class="overflow-hidden rounded"
          >
            <div
              class="flex items-center gap-x-3 border-b border-gray-600/5 bg-gray-10 p-2"
            >
              <div class="text-gray-700">{{ userVideo.title }}</div>
            </div>
          </li>
        </ol>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="closeCategoryModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../Loading.vue";

export default {
  name: "ShowCategories",
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      filter: {
        title: "",
      },
      categorys: [],
      categoryVideos: {},
      videos: [],
      userVideos: [],
    };
  },
  computed: {
    ...mapGetters([
      "fetchAssignedUserVideos",
      "fetchAssignedVideos",
      "fetchAssignedUserCategorys",
      "fetchToken",
    ]),
  },
  methods: {
    ...mapActions([
      "getAssignedUserCategorys",
      "getAssignedVideos",
      "getAssignedUserVideos",
    ]),
    closeCategoryModal() {
      this.$emit("close");
    },
  },
  watch: {
    async fetchAssignedUserCategorys(new_val) {
      for (const item of new_val) {
        const newObj = {};
        newObj["catname"] = item.catname;
        newObj["id"] = item.id;
        this.categorys.push(newObj);
      }
    },
  },
  async created() {
    if (this.id) {
      this.getAssignedUserCategorys({ id: this.id, token: this.fetchToken })
        .then(async () => {
          const categorys = this.fetchAssignedUserCategorys;
          for (const category of categorys) {
            await this.getAssignedVideos({
              id: category.id,
              token: this.fetchToken,
            });
            this.categoryVideos[category.id] = this.fetchAssignedVideos;
          }
          this.loading = false;
          console.log("Category loaded successfully");
        })
        .catch((err) => {
          this.loading = false;
          console.log("Error: ", err);
        });
    }
    this.getAssignedUserVideos({ id: this.id, token: this.fetchToken })
      .then(() => {
        this.userVideos = this.fetchAssignedUserVideos;
        this.loading = false;
        console.log("Video loaded successfully");
      })
      .catch((err) => {
        this.loading = false;
        console.log("Error: ", err);
      });
  },
};
</script>