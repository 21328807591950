import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import store from './store'

import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild, 
    TransitionRoot, 
}   from "@headlessui/vue";

import {
    Bars3Icon,
    BellIcon,
    Cog6ToothIcon,
    XMarkIcon, 
    VideoCameraIcon,
    ViewColumnsIcon,
    EyeIcon,
    ArrowRightOnRectangleIcon,
    ExclamationTriangleIcon,
}   from "@heroicons/vue/24/outline";

import { 
    ChevronDownIcon, 
    MagnifyingGlassIcon,
    TrashIcon,
    PencilSquareIcon,
    EllipsisHorizontalIcon
}   from "@heroicons/vue/20/solid";

import VueMultiselect from 'vue-multiselect';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App);

  
app.component('Dialog', Dialog);
app.component('DialogPanel', DialogPanel);
app.component('DialogTitle', DialogTitle);
app.component('Menu', Menu);
app.component('MenuButton', MenuButton);
app.component('MenuItem', MenuItem);
app.component('MenuItems', MenuItems);
app.component('TransitionChild', TransitionChild);
app.component('TransitionRoot', TransitionRoot);
app.component('ChevronDownIcon', ChevronDownIcon);
app.component('MagnifyingGlassIcon', MagnifyingGlassIcon);
app.component('Bars3Icon', Bars3Icon);
app.component('BellIcon', BellIcon);
app.component('Cog6ToothIcon', Cog6ToothIcon);
app.component('XMarkIcon', XMarkIcon);
app.component('TrashIcon', TrashIcon);
app.component('PencilSquareIcon', PencilSquareIcon);
app.component('VideoCameraIcon', VideoCameraIcon);
app.component('ViewColumnsIcon', ViewColumnsIcon);
app.component('EllipsisHorizontalIcon', EllipsisHorizontalIcon);
app.component('EyeIcon', EyeIcon);
app.component('ArrowRightOnRectangleIcon', ArrowRightOnRectangleIcon);
app.component('ExclamationTriangleIcon', ExclamationTriangleIcon);
app.component('VueMultiselect', VueMultiselect)

app.use(VueToast);
app.use(store)
app.use(router).mount('#app')
